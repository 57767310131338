import React from 'react';

function Pesquisas() {
  return (
    <div className="col-lg-8 mx-auto p-3 py-md-5">
      <header className="d-flex align-items-center pb-3 mb-5 border-bottom">
        <div className="d-flex align-items-center text-dark text-decoration-none">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="32" className="me-2" viewBox="0 0 118 94" role="img">
            <title>Bootstrap</title>
            <path fillRule="evenodd" clipRule="evenodd" d="M24.509 0c-6.733 0-11.715 5.893-11.492 12.284.214 6.14-.064 14.092-2.066 20.577C8.943 39.365 5.547 43.485 0 44.014v5.972c5.547.529 8.943 4.649 10.951 11.153 2.002 6.485 2.28 14.437 2.066 20.577C12.794 88.106 17.776 94 24.51 94H93.5c6.733 0 11.714-5.893 11.491-12.284-.214-6.14.064-14.092 2.066-20.577 2.009-6.504 5.396-10.624 10.943-11.153v-5.972c-5.547-.529-8.934-4.649-10.943-11.153-2.002-6.484-2.28-14.437-2.066-20.577C105.214 5.894 100.233 0 93.5 0H24.508zM80 57.863C80 66.663 73.436 72 62.543 72H44a2 2 0 01-2-2V24a2 2 0 012-2h18.437c9.083 0 15.044 4.92 15.044 12.474 0 5.302-4.01 10.049-9.119 10.88v.277C75.317 46.394 80 51.21 80 57.863zM60.521 28.34H49.948v14.934h8.905c6.884 0 10.68-2.772 10.68-7.727 0-4.643-3.264-7.207-9.012-7.207zM49.948 49.2v16.458H60.91c7.167 0 10.964-2.876 10.964-8.281 0-5.406-3.903-8.178-11.425-8.178H49.948z" fill="currentColor"></path>
          </svg>
          <span className="fs-4">Ciência e Tecnologia</span>
        </div>
      </header>

      <main>
        <h1>Mídias Digitais e Experiência de Uso</h1>
        <p className="fs-5 col-md-8">Lutar pela pesquisa no Brasil é um grande desafio, por isso, estamos fazendo nosso melhor para deixar esta página do jeito que todo pesquisado gosta: repleta de estudos científicos e com publicação de resultados.</p>
        <div className="my-md-5">
          <a href="#" className="btn btn-primary btn-lg px-md-4">Conheça a Metodologia</a>
        </div>

        <hr className="col-3 col-md-2 mb-5" />

        <div className="row g-5">
          <div className="col-md-6">
            <h2>Linha de Pesquisa</h2>
            <p>Lutar pela pesquisa no Brasil é um grande desafio, por isso, estamos fazendo nosso melhor para deixar esta página do jeito que todo pesquisado gosta: repleta de estudos científicos e com publicação de resultados.</p>
            <ul className="icon-list">
              <li><a href="https://github.com/twbs/bootstrap-npm-starter" rel="noopener noreferrer" target="_blank">Bootstrap npm starter</a></li>
              <li className="text-muted">Bootstrap Parcel starter (coming soon!)</li>
            </ul>
          </div>

          <div className="col-md-6">
            <h2>Temas</h2>
            <p>Read more detailed instructions and documentation on using or contributing to Bootstrap.</p>
            <ul className="icon-list">
              <li><a href="/docs/5.1/getting-started/introduction/">Bootstrap quick start guide</a></li>
              <li><a href="/docs/5.1/getting-started/webpack/">Bootstrap Webpack guide</a></li>
              <li><a href="/docs/5.1/getting-started/parcel/">Bootstrap Parcel guide</a></li>
              <li><a href="/docs/5.1/getting-started/contribute/">Contributing to Bootstrap</a></li>
            </ul>
          </div>
        </div>
      </main>

      <div className="b-example-divider"></div>

      <div className="container px-4 py-5" id="icon-grid">
        <h2 className="pb-2 border-bottom">Palavras-Chave</h2>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
          {renderKeywordItem("bootstrap", "Featured title", "Paragraph of text beneath the heading to explain the heading.")}
          {renderKeywordItem("cpu-fill", "Featured title", "Paragraph of text beneath the heading to explain the heading.")}
          {renderKeywordItem("calendar3", "Featured title", "Paragraph of text beneath the heading to explain the heading.")}
          {renderKeywordItem("home", "Featured title", "Paragraph of text beneath the heading to explain the heading.")}
          {renderKeywordItem("speedometer2", "Featured title", "Paragraph of text beneath the heading to explain the heading.")}
          {renderKeywordItem("toggles2", "Featured title", "Paragraph of text beneath the heading to explain the heading.")}
          {renderKeywordItem("geo-fill", "Featured title", "Paragraph of text beneath the heading to explain the heading.")}
          {renderKeywordItem("tools", "Featured title", "Paragraph of text beneath the heading to explain the heading.")}
        </div>
      </div>
    </div>
  );
}

function renderKeywordItem(icon, title, description) {
  return (
    <div className="col d-flex align-items-start">
      <svg className="bi text-muted flex-shrink-0 me-3" width="1.75em" height="1.75em">
        <use xlinkHref={`#${icon}`} />
      </svg>
      <div>
        <h4 className="fw-bold mb-0">{title}</h4>
        <p>{description}</p>
      </div>
    </div>
  );
}

export default Pesquisas;